import { useEffect } from '@storybook/addons';
import React, { useMemo } from 'react';
import { IntersectionContext, ObserverImpl } from './context';

export type IntersectionProviderProps = React.PropsWithChildren<{
  target: HTMLElement | null;
  rootMargin?: string;
}>;

export const IntersectionProvider = ({
  target,
  rootMargin,
  children
}: IntersectionProviderProps) => {
  const observer = useMemo(() => new ObserverImpl(target, rootMargin), [target, rootMargin]);
  useEffect(() => {
    return () => observer.disconnect();
  }, [observer]);
  return <IntersectionContext.Provider value={observer}>{children}</IntersectionContext.Provider>;
};
