import { parse } from 'querystring';
import { createSelector } from 'reselect';
import {
  latestExperienceSelector,
  manifestAccountInfoSelector
} from '../../redux/manifest/selector';

export const preferredAdvisorSelector = createSelector(
  manifestAccountInfoSelector,
  latestExperienceSelector,
  (manifest, experience) => {
    let advisorString: string | undefined;
    const urlAdvisor = window.location.search
      ? parse(window.location.search.substring(1)).advisor // Trim the leading ?
      : undefined;
    if (urlAdvisor) {
      if (Array.isArray(urlAdvisor)) {
        advisorString = urlAdvisor[0];
      } else {
        return urlAdvisor;
      }
    }
    if (!advisorString) {
      advisorString = manifest.advisorEmail || experience.value?.advisor;
    }
    return advisorString;
  }
);
