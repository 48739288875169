import {
  FOG_60,
  OwnUpBodyMedium,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpSmallBody
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';
import starShieldIcon from '../../../assets/star-shield.svg';
import { LenderBio } from '../../../components/lender-bio';
import { LenderBio as LenderBioType } from '../../redux/selector';

const GridWrapper = styled.div`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 32px 24px;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin: 40px 40px;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 48px 40px;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  p:first-child {
    margin-bottom: 8px;
  }
`;

const HorizontalLine = styled.hr`
  border-top: 1px solid ${FOG_60};
  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin: 40px 0;
  }
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin: 48px 0;
  }
`;

const ShieldImage = styled.img`
  align-self: baseline;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-right: 20px;
  }
  ${({ theme }) => theme.breakpoints.up('xs')} {
    margin-right: 16px;
    margin-bottom: 16px;
  }
`;

const CommitmentTextGridItem = styled(OwnUpGridItem)`
  ${({ theme }) => theme.breakpoints.up('xs')} {
    display: block;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    display: flex;
  }
`;

export const LenderReputation = ({ lenderReview, highlights }: LenderBioType) => (
  <GridWrapper>
    <LenderBio highlights={highlights} lenderReview={lenderReview} />
    <OwnUpGridContainer variant="slim" spacing={0}>
      <OwnUpGridItem xs={12}>
        <HorizontalLine />
      </OwnUpGridItem>
      <CommitmentTextGridItem xs={12} md={10} lg={8}>
        <ShieldImage src={starShieldIcon} alt="" />
        <TextContainer>
          <OwnUpBodyMedium variant="body1">Our commitment to customer service</OwnUpBodyMedium>
          <OwnUpSmallBody variant="body1">
            Our Home Advisors are in constant contact with each of our lenders to advocate for the
            customer at each step of the underwriting process and ensure adherence to our customer
            service standards. Lenders are required to maintain a high customer satisfaction score
            in order to participate in our marketplace.
          </OwnUpSmallBody>
        </TextContainer>
      </CommitmentTextGridItem>
    </OwnUpGridContainer>
  </GridWrapper>
);
