import {
  OwnUpComponentLibraryProvider,
  OwnUpGridItem,
  OwnUpLogoLink,
  OwnUpNavBar
} from '@rategravity/own-up-component-library';
import React from 'react';
import styled from 'styled-components';

const AccountNavBar = styled(OwnUpNavBar)`
  position: relative;
`;

const LogoContainer = styled(OwnUpGridItem)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface HeaderProps {}

export const Header = (_: HeaderProps) => (
  <OwnUpComponentLibraryProvider>
    <AccountNavBar>
      <LogoContainer>
        <OwnUpLogoLink href={process.env.WWW} />
      </LogoContainer>
      <OwnUpGridItem style={{ flexGrow: 1 }} />
    </AccountNavBar>
  </OwnUpComponentLibraryProvider>
);
